import { useContext } from 'react'

import { AlertContext } from '@src/providers/alert_provider'

export const useAlerts = () => {
  const {
    alerts,
    displaySuccessAlert,
    displayErrorAlert,
    removeAlert,
    beginBatchAlert,
    displayBatchAlert
  } = useContext(AlertContext)

  return {
    alerts,
    displaySuccessAlert,
    displayErrorAlert,
    removeAlert,
    beginBatchAlert,
    displayBatchAlert
  }
}
