import { Theme } from '@mui/material/styles'

type ThemeInterface = Exclude<Theme['components'], undefined>

const MuiTablePagination: ThemeInterface['MuiTablePagination'] = {
  styleOverrides: {
    selectLabel: {
      margin: 0
    },
    displayedRows: {
      margin: 0
    }
  }
}

export default MuiTablePagination
