import React from 'react'

import FeedbackAlert from '@components/feedback_alert'
import { useAlerts } from '@hooks/use_alerts'

import * as styles from './styles'

const Alerts = () => {
  const { alerts, removeAlert } = useAlerts()

  return (
    <div css={styles.alertContainer}>
      {alerts.map((alert, index) => (
        <FeedbackAlert
          key={`alert_${alert.type}_${index}`}
          type={alert.type}
          title={alert.title}
          actions={[
            {
              text: 'DISMISS',
              onClick: () => removeAlert(index),
              dismiss: true
            }
          ]}
          details={alert.details}
        />
      ))}
    </div>
  )
}

export default Alerts
