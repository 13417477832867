import { Theme } from '@mui/material/styles'

type ThemeInterface = Exclude<Theme['components'], undefined>

const MuiButton: ThemeInterface['MuiButton'] = {
  defaultProps: {
    variant: 'contained'
  },
  styleOverrides: {
    root: {
      boxShadow: 'none',
      justifyContent: 'center',
      alignContent: 'center',
      textTransform: 'none',
      padding: '10px 20px',
      fontSize: 16,
      fontWeight: 600
    },
    containedPrimary: {
      color: 'white'
    },
    outlined: {
      borderWidth: 2,
      '&:hover': {
        borderWidth: 2
      },
      '&:disabled': {
        borderWidth: 2
      }
    }
  }
}

export default MuiButton
