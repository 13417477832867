/* istanbul ignore file */
import { createTheme } from '@mui/material/styles'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

import components from './components'
import typography from './typography'

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: Colours('uiBlue', 300),
      contrastText: Colours('white', 300)
    },
    secondary: {
      main: Colours('sourCherry', 300)
    }
  },
  shape: {
    borderRadius: 3
  },
  typography,
  components
})

export default MuiTheme
