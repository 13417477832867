import DrawerOpenIcon from '@mui/icons-material/Menu'
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton
} from '@mui/material'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { isListItemSelected, shouldShowSection } from './helpers'
import { INavigationBarDrawerProps, INavigationItem } from './types'

const NavigationBarDrawer = ({
  navigationItems
}: INavigationBarDrawerProps): JSX.Element => {
  const router = useRouter()

  const [navigationDrawerOpen, setNavigationDrawerOpen] =
    useState<boolean>(false)

  const toggleDrawer = (open: boolean) => {
    return (event: React.KeyboardEvent | React.MouseEvent): void => {
      if (
        event.type === 'keydown' &&
        (event as React.KeyboardEvent).key === 'Tab'
      )
        return

      setNavigationDrawerOpen(open)
    }
  }

  const renderListItem = (navItem: INavigationItem): JSX.Element | null => {
    if (!shouldShowSection(navItem.showSection)) return null

    const Icon = navItem.icon
    return (
      <ListItem
        key={navItem.text}
        disablePadding
      >
        <ListItemButton
          role='menuitem'
          component='a'
          href={navItem.path}
          selected={isListItemSelected(navItem.path, router.pathname)}
        >
          <Icon sx={{ mr: 2 }} />
          {navItem.text}
        </ListItemButton>
      </ListItem>
    )
  }

  const renderDrawerOpenIcon = (): JSX.Element => {
    return (
      <IconButton
        size='large'
        edge='start'
        color='inherit'
        aria-label='menu'
        sx={{ mr: 2 }}
        onClick={toggleDrawer(true)}
      >
        <DrawerOpenIcon />
      </IconButton>
    )
  }

  const renderDrawer = (): JSX.Element => {
    return (
      <Drawer
        anchor={'left'}
        open={navigationDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box
          role='presentation'
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List sx={{ width: 240 }}>
            {navigationItems.map((navItem) => renderListItem(navItem))}
          </List>
        </Box>
      </Drawer>
    )
  }

  return (
    <React.Fragment>
      {renderDrawerOpenIcon()}
      {renderDrawer()}
    </React.Fragment>
  )
}

export default NavigationBarDrawer
