import Head from 'next/head'

const Metadata: React.FC = () => (
  <Head>
    <title key='title'>CMS</title>
    <link
      rel='shortcut icon'
      href='/favicon.ico'
    />
  </Head>
)

export default Metadata
