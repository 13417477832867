import { SerializedStyles, css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Fonts from '@thebeansgroup/ui_style_helpers/fonts'

import { BORDER_RADIUS } from './constants'

export const action = css`
  position: relative;
  cursor: pointer;
  float: right;
  text-decoration: underline;
  font-family: ${Fonts('gibsonSemiBold')};
  font-size: 12px;
  letter-spacing: 1.2px;
  margin-left: 22px;
  color: ${Colours('white', 300)};
  background-color: transparent;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
`

export const details = (type: string): SerializedStyles => css`
  color: ${Colours('white', 300)};
  font-size: 16px;
  font-family: ${Fonts('gibson')};
  padding: 16px;
  background-color: ${Colours(type, 300)};
  border-radius: ${BORDER_RADIUS}px;
  margin-top: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
`

export const detailsItem = css`
  font-size: 14px;
  margin: 2px 0;
  opacity: 0.9;
`

export const detailsButton = css`
  background: none;
  border: 0;
  padding: 0;
  color: ${Colours('white', 300)};
  font-family: ${Fonts('gibson')};
  margin-top: 12px;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`

export const detailsButtonText = css`
  margin-left: 10px;
`

export const alert = (type: string): SerializedStyles => css`
  height: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 16px 20px;
  margin-bottom: 8px;
  background-color: ${Colours(type, 300)};
  border-radius: 0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px;
`

export const title = css`
  color: ${Colours('white', 300)};
  font-size: 16px;
  font-family: ${Fonts('gibsonSemiBold')};
  line-height: 16px;
`
