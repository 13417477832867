import { useMutation } from '@apollo/client'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import { Typography, Menu, MenuItem, Button } from '@mui/material'
import React, { useState } from 'react'

import { Country } from '@src/relay/schema.types'

import Flag from '@components/flags'
import { getCountryCode } from '@helpers/country_code'
import { useHistory } from '@hooks/react-router-dom'
import { useSetting, useCountries } from '@hooks/use_viewer_data'

import { UPDATE_SETTING_MUTATION, WORLDWIDE_COUNTRY } from './constants'
import { getUpdateSettingMutationVariables } from './helpers'
import { IWorldwideCountry } from './types'

const NavigationBarCountryMenu = (): JSX.Element => {
  const setting = useSetting()
  const countries = useCountries()
  const history = useHistory()
  const [updateSettingMutation] = useMutation(UPDATE_SETTING_MUTATION)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenCountryMenu = (
    event: React.MouseEvent<HTMLElement>
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCountryMenu = (): void => {
    setAnchorEl(null)
  }

  const handleCountryMenuItemClick = (countryCode: string): void => {
    handleCloseCountryMenu()
    updateSettingMutation({
      variables: getUpdateSettingMutationVariables(
        setting?.settingId,
        countryCode
      ),
      onCompleted: () => {
        history.reload()
      }
    })
  }

  const renderMenuButton = (): JSX.Element => {
    return (
      <Button
        onClick={handleOpenCountryMenu}
        variant='text'
        sx={{ color: 'white' }}
      >
        <Flag countryCode={getCountryCode(setting?.country)} />
        <KeyboardArrowDownIcon />
      </Button>
    )
  }

  const renderMenuItem = (
    country: Country | IWorldwideCountry
  ): JSX.Element => {
    return (
      <MenuItem
        key={country.name}
        onClick={(): void => handleCountryMenuItemClick(country.code)}
      >
        <Flag countryCode={getCountryCode(country.code)} />
        <Typography sx={{ ml: 3 }}>{country.name}</Typography>
      </MenuItem>
    )
  }

  const renderMenu = (): JSX.Element => {
    return (
      <Menu
        sx={{ mt: '49px', ml: '-48px' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseCountryMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {countries?.edges.map((country) => renderMenuItem(country.node))}
        {renderMenuItem(WORLDWIDE_COUNTRY)}
      </Menu>
    )
  }

  return (
    <React.Fragment>
      {renderMenuButton()}
      {renderMenu()}
    </React.Fragment>
  )
}

export default NavigationBarCountryMenu
