import { useState } from 'react'

import ChevronIcon from '@thebeansgroup/ui_icons/chevron_icon'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

import * as styles from './styles'
import { IFeedbackAlertProps, IFeedbackAlertAction } from './types'

const FeedbackAlert: React.FC<IFeedbackAlertProps> = ({
  actions,
  type,
  title,
  details
}) => {
  const [detailsVisible, setDetailsVisible] = useState(false)

  const toggleDetailsVisibility = (): void => {
    setDetailsVisible((prev) => !prev)
  }

  const renderAction = (
    action: IFeedbackAlertAction,
    index: number
  ): JSX.Element => {
    const key = `action_${action.text}_${index}`
    return (
      <button
        key={key}
        onClick={action.onClick}
        css={styles.action}
      >
        {action.text}
      </button>
    )
  }

  const renderDetailsButton = (): JSX.Element | null => {
    if (!details) return null

    return (
      <button
        css={styles.detailsButton}
        onClick={toggleDetailsVisibility}
      >
        <ChevronIcon
          colour={Colours('white', 300)}
          rotateDegrees={detailsVisible ? 90 : 0}
          label=''
        />
        <span css={styles.detailsButtonText}>View details</span>
      </button>
    )
  }

  const renderDetails = (): JSX.Element | null => {
    if (!details || !detailsVisible) return null

    return (
      <div css={styles.details(type)}>
        {details.map((detail, index) => (
          <div
            key={index}
            css={styles.detailsItem}
          >
            {`- ${detail}`}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div css={styles.alert(type)}>
      {actions.map(renderAction)}
      <div css={styles.title}>{title}</div>
      {renderDetailsButton()}
      {renderDetails()}
    </div>
  )
}

export default FeedbackAlert
