import { MutationUpdateSettingArgs } from '@src/relay/schema.types'

export const getUpdateSettingMutationVariables = (
  settingId = '',
  country: string
): MutationUpdateSettingArgs => ({
  input: {
    settingId,
    country
  }
})
