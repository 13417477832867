import {
  Discount as DiscountIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Campaign as CampaignIcon,
  Poll as PollIcon,
  Reviews as ReviewsIcon,
  ViewCarousel as ViewCarouselIcon,
  LocalOffer as LocalOfferIcon,
  Collections as CollectionsIcon,
  Category as CategoryIcon,
  Link as LinkIcon,
  WebAsset as WebAssetIcon,
  ShoppingBasket as ShoppingBasketIcon,
  FilterAlt as FilterAltIcon,
  Dashboard as DashboardIcon,
  SportsEsports as SportsEsportsIcon,
  Translate as TranslateIcon
} from '@mui/icons-material'

import featureToggle from '@helpers/feature_toggle'
import { createUrl } from '@helpers/routes'

export const NAVIGATION_DATA = [
  {
    text: 'Discounts',
    path: createUrl('discounts'),
    icon: DiscountIcon
  },
  {
    text: 'Featured',
    path: createUrl('featured'),
    icon: LocalOfferIcon
  },
  {
    text: 'Collections',
    path: createUrl('collections'),
    icon: CollectionsIcon
  },
  {
    text: 'Categories',
    path: createUrl('categories'),
    icon: CategoryIcon
  },
  {
    text: 'Brands',
    path: createUrl('brand_list'),
    icon: SupervisorAccountIcon
  },
  {
    text: 'Campaigns',
    path: createUrl('campaigns'),
    icon: CampaignIcon
  },
  {
    text: 'Connect',
    path: createUrl('connect'),
    icon: LinkIcon
  },
  {
    text: 'Media',
    path: createUrl('promo_blocks'),
    icon: WebAssetIcon
  },
  {
    text: 'Carousel',
    path: createUrl('carousel'),
    icon: ViewCarouselIcon
  },
  {
    text: 'Products',
    path: createUrl('products'),
    icon: ShoppingBasketIcon
  },
  {
    text: 'Content',
    path: createUrl('content'),
    icon: DashboardIcon
  },
  {
    text: 'Polls',
    path: createUrl('polls'),
    icon: PollIcon
  },
  {
    text: 'Reviews',
    path: createUrl('reviews'),
    icon: ReviewsIcon
  },
  {
    text: 'Facets',
    path: createUrl('facets'),
    icon: FilterAltIcon
  },
  {
    text: 'Games',
    path: createUrl('games'),
    icon: SportsEsportsIcon,
    showSection: featureToggle.isEnabled('promoGame')
  },
  {
    text: 'Instant Translator',
    path: createUrl('instant.translator'),
    icon: TranslateIcon,
    showSection: featureToggle.isEnabled('instantTranslator')
  }
]

export const PION_LOGO_PATH = '/pion_logo.svg'

export const PION_LOGO_ALT = 'Pion Logo'
