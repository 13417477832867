import PropTypes from 'prop-types'

import { useScopes } from '@hooks/use_viewer_data'

import { isViewerAuthorised, redirectToLogin } from './helpers'

const IsAuthorised = ({ children, scopes }) => {
  const viewerScopes = useScopes()
  if (!isViewerAuthorised(viewerScopes, scopes)) {
    redirectToLogin()
    return null
  }
  return children
}

IsAuthorised.propTypes = {
  children: PropTypes.any.isRequired,
  scopes: PropTypes.array
}

IsAuthorised.defaultProps = {
  scopes: ['*']
}

export default IsAuthorised
