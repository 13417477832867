import PropTypes from 'prop-types'
import React, { useState } from 'react'

import {
  getBatchAlertIndex,
  toArray,
  updateBatchAlert,
  addBatchAlert,
  createBatchAlert
} from './helpers'

export const AlertContext = React.createContext({
  alerts: [],
  displaySuccessAlert: /* istanbul ignore next */ (text) => {},
  displayErrorAlert: /* istanbul ignore next */ (text, details) => {},
  removeAlert: /* istanbul ignore next */ (index) => {},
  beginBatchAlert: /* istanbul ignore next */ (
    title,
    details,
    actions,
    total
  ) => {},
  displayBatchAlert: /* istanbul ignore next */ (
    title,
    details,
    actions,
    total,
    key
  ) => {}
})

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([])

  const displaySuccessAlert = (text) => addAlert('alertGreen', text)
  const displayErrorAlert = (text, details) =>
    addAlert('alertRed', text, details)

  const addAlert = (type, title, details) => {
    setAlerts((prevAlerts) => [
      ...prevAlerts,
      {
        type,
        title,
        details
      }
    ])
  }

  const removeAlert = (index) => {
    const newAlerts = [...alerts]
    newAlerts.splice(index, 1)
    setAlerts(newAlerts)
  }

  const displayBatchAlert = (title, details, actions, total, key) =>
    handleBatchAlert('alertOrange', title, details, actions, total, key)

  const beginBatchAlert = (title, details, actions, total) => {
    const key = `BATCH_ALERT_${Date.now()}_${total}`
    handleBatchAlert('alertOrange', title, details, actions, total, key)
    return key
  }

  const handleBatchAlert = (type, title, details, actions, total, key) => {
    const toDisplay = {
      type,
      title,
      details: toArray(details),
      actions,
      total,
      key
    }
    setAlerts((prevAlerts) => displayAlert(prevAlerts, toDisplay))
  }

  const displayAlert = (prevAlerts, toDisplay) => {
    const batchAlertIndex = getBatchAlertIndex(toDisplay.key, prevAlerts)
    if (batchAlertIndex !== -1) {
      const { count, details } = prevAlerts[batchAlertIndex]
      const alert = createBatchAlert(toDisplay, count + 1, details)
      return updateBatchAlert(prevAlerts, alert, batchAlertIndex)
    }

    const alert = createBatchAlert(toDisplay)
    return addBatchAlert(prevAlerts, alert)
  }

  return (
    <AlertContext.Provider
      value={{
        alerts,
        displaySuccessAlert,
        displayErrorAlert,
        removeAlert,
        beginBatchAlert,
        displayBatchAlert
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}

AlertProvider.propTypes = {
  children: PropTypes.any.isRequired
}
