import { gql } from '@apollo/client'

export const UPDATE_SETTING_MUTATION = gql`
  mutation updateSettingMutation($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      setting {
        settingId
        country
      }
    }
  }
`

export const WORLDWIDE_COUNTRY = { code: '', name: 'World' }
