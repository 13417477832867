/* istanbul ignore file */
import dynamic from 'next/dynamic'

import { IFlagProps } from './types'

const getDynamicFlag = (countryCode: string): React.ComponentType =>
  dynamic(
    () =>
      import(
        `@thebeansgroup/utility-belt/build/flags/${countryCode.toLowerCase()}.js`
      ),
    { ssr: false }
  )

const Flag = ({ countryCode }: IFlagProps): JSX.Element | null => {
  if (!countryCode) return null

  const FlagComponent = getDynamicFlag(countryCode)

  return <FlagComponent />
}

export default Flag
