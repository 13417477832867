export const getBatchAlertIndex = (key, alerts) =>
  alerts.findIndex((alert) => alert.key === key)

export const toArray = (details) => {
  if (!details) return []

  return details instanceof Array ? details : [details.toString()]
}

export const addBatchAlert = (prevAlerts, alert) => [
  ...prevAlerts,
  {
    ...alert
  }
]

export const updateBatchAlert = (prevAlerts, newAlert, batchAlertIndex) => {
  const newAlerts = [...prevAlerts]
  newAlerts[batchAlertIndex] = {
    ...newAlert
  }
  return newAlerts
}

export const createBatchAlert = (alert, count = 0, details = []) => {
  const title = `${count}/${alert.total} ${alert.title}`
  return { ...alert, count, title, details: details.concat(alert.details) }
}
