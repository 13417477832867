import { captureException, withScope } from '@sentry/nextjs'
import PropTypes from 'prop-types'
import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    }
  }

  componentDidCatch(error, errorInfo) {
    withScope((scope) => {
      scope.setExtras(errorInfo)
      captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      return <div>An error occured</div>
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired
}

export default ErrorBoundary
