import { Theme } from '@mui/material'

const typography: Partial<Theme['typography']> = {
  fontFamily: "'Gibson', Helvetica, Arial, Sans Serif",
  fontWeightRegular: 500,
  fontWeightBold: 900,
  fontWeightLight: 300,
  fontWeightMedium: 700
}

export default typography
