import { createUrl, prependModernHost } from '@helpers/routes'

export const convertScopeArrayToRegExpArray = (scopes) => {
  return scopes.map((scope) => {
    return new RegExp(scope.replace(/[*]/g, '.*'))
  })
}

export const viewerContainsPageScope = (pageScopes, scopes) => {
  let hasScope = false
  const pageScopesRegExp = convertScopeArrayToRegExpArray(pageScopes)
  scopes.forEach((scope) => {
    pageScopesRegExp.forEach((scopeTest) => {
      if (scope.node.name && scopeTest.test(scope.node.name)) {
        hasScope = true
      }
    })
  })
  return hasScope
}

export const isViewerAuthorised = (viewerScopes, pageScopes) => {
  if (!viewerScopes) return false

  return viewerContainsPageScope(pageScopes, viewerScopes.edges)
}

export const redirectToLogin = () => {
  const redirect = window.location.pathname
  const loginRoute = createUrl('login.redirect', { redirect })
  window.location = prependModernHost(loginRoute)
}
