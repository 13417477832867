import { css } from '@emotion/react'

export const alertContainer = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 845px;
  margin: 0 auto;
  z-index: 9999;
`
