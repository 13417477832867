import { createContext } from 'react'

import { IViewerDataProviderProps } from './types'

export const SettingContext = createContext<TSettingContext>(null)
export const UserEmailContext = createContext<TUserEmailContext>(null)
export const CountriesContext = createContext<TCountriesContext>(null)
export const ScopesContext = createContext<TScopesContext>(null)
export const ConsumersContext = createContext<TConsumersContext>(null)

export const ViewerDataProvider: React.FC<IViewerDataProviderProps> = ({
  data,
  children
}: IViewerDataProviderProps) => {
  return (
    <SettingContext.Provider value={data?.viewer?.setting}>
      <UserEmailContext.Provider value={data?.viewer?.email}>
        <CountriesContext.Provider value={data?.viewer?.countries}>
          <ScopesContext.Provider value={data?.viewer?.scopes}>
            <ConsumersContext.Provider value={data?.viewer?.consumers}>
              {children}
            </ConsumersContext.Provider>
          </ScopesContext.Provider>
        </CountriesContext.Provider>
      </UserEmailContext.Provider>
    </SettingContext.Provider>
  )
}
