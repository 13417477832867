/* istanbul ignore file */
/* eslint-disable @thebeansgroup/sb/components-file-structure */

import { ApolloProvider, useQuery } from '@apollo/client'
import { ThemeProvider } from '@mui/material'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'

import BaseStyles from '@thebeansgroup/ui_base_styles'

import IsAuthorised from '@src/components/is_authorised'
import MuiTheme from '@src/mui_theme'
import { AlertProvider } from '@src/providers/alert_provider'
import { ViewerDataProvider } from '@src/providers/viewer_data_provider'

import Alerts from '@components/alerts'
import ErrorBoundary from '@components/error_boundary'
import Metadata from '@components/metadata'
import NavigationBar from '@components/navigation_bar'
import PageLoading from '@components/page_loading'
import { VIEWER_DATA_QUERY, COUNTRY_ALPHABETICAL_SORT } from '@constants/app'
import { isLoginRoute } from '@helpers/routes'

// eslint-disable-next-line @thebeansgroup/sb/prefer-import-alias
import { client } from '../../apollo-client'

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter()

  const { loading, error, data } = useQuery(VIEWER_DATA_QUERY, {
    variables: {
      sortBy: COUNTRY_ALPHABETICAL_SORT
    },
    client
  })

  if (loading) return <PageLoading />

  if (error) return <div>{error.message}</div>

  const renderLoginPage = (): JSX.Element | null => {
    if (!isLoginRoute(router)) return null

    return (
      <AlertProvider>
        <Alerts />
        <Component {...pageProps} />
      </AlertProvider>
    )
  }

  const renderPage = (): JSX.Element | null => {
    if (isLoginRoute(router)) return null

    return (
      <IsAuthorised>
        <AlertProvider>
          <Alerts />
          <NavigationBar />
          <Component {...pageProps} />
        </AlertProvider>
      </IsAuthorised>
    )
  }

  return (
    <React.Fragment>
      <BaseStyles />
      <Metadata />
      <ApolloProvider client={client}>
        <ViewerDataProvider data={data}>
          <ThemeProvider theme={MuiTheme}>
            <ErrorBoundary>
              {renderLoginPage()}
              {renderPage()}
            </ErrorBoundary>
          </ThemeProvider>
        </ViewerDataProvider>
      </ApolloProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.any.isRequired
}

export default App
