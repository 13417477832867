var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"EV0dEmjv4oCZHAYtdNgOF"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const APP_ENV = process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV

if (['staging', 'production'].includes(APP_ENV)) {
  init({
    dsn: SENTRY_DSN,
    environment: APP_ENV,
    allowUrls: [/https?:\/\/((cdn|www)\.)?studentbeans\.com/]
  })
}
