import { gql } from '@apollo/client'

export const COUNTRY_ALPHABETICAL_SORT = { column: 'NAME', direction: 'ASC' }

export const VIEWER_DATA_QUERY = gql`
  query viewerDataQuery($sortBy: CountrySort!) {
    viewer {
      viewerId
      email
      setting {
        settingId
        country
      }
      countries(first: 100, sortBy: $sortBy) {
        edges {
          node {
            countryId
            name
            code
          }
        }
      }
      scopes(first: 100) {
        edges {
          node {
            name
          }
        }
      }
      consumers(first: 100) {
        edges {
          node {
            uid
            name
            internal
          }
        }
      }
    }
  }
`
