import { AppBar, Box, Toolbar, Container } from '@mui/material'

import NavigationBarCountryMenu from '@components/navigation_bar_country_menu'
import NavigationBarDrawer from '@components/navigation_bar_drawer'
import NavigationBarUserMenu from '@components/navigation_bar_user_menu'
import { getBackgroundColor } from '@helpers/style_helpers/envs_style'

import { NAVIGATION_DATA, PION_LOGO_PATH, PION_LOGO_ALT } from './constants'

const NavigationBar = (): JSX.Element => {
  return (
    <AppBar
      position='static'
      sx={{ backgroundColor: getBackgroundColor() }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <img
            alt={PION_LOGO_ALT}
            width={'100'}
            style={{ marginRight: '16px' }}
            src={PION_LOGO_PATH}
          />
          <Box sx={{ flexGrow: 1 }}>
            <NavigationBarDrawer navigationItems={NAVIGATION_DATA} />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <NavigationBarCountryMenu />
            <NavigationBarUserMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default NavigationBar
