import { useContext } from 'react'

import {
  SettingContext,
  UserEmailContext,
  CountriesContext,
  ScopesContext,
  ConsumersContext
} from '@src/providers/viewer_data_provider'

export const useSetting = () => useContext(SettingContext)
export const useUserEmail = () => useContext(UserEmailContext)
export const useCountries = () => useContext(CountriesContext)
export const useScopes = () => useContext(ScopesContext)
export const useConsumers = () => useContext(ConsumersContext)
