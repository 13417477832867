import objectAssign from 'object-assign'

import features from '@constants/features'

let featureToggles = {} // keep it private

class FeatureToggle {
  constructor(features) {
    featureToggles = features
  }

  _featuresArrayToObject(featuresArray) {
    const featuresObject = {}
    featuresArray.forEach((item) => {
      featuresObject[item] = true
    })
    return featuresObject
  }

  load(features, clean) {
    if (Array.isArray(features)) {
      features = this._featuresArrayToObject(features)
    }
    if (clean) featureToggles = {}
    return objectAssign(featureToggles, features)
  }

  isEnabled(feature) {
    return !!featureToggles[feature]
  }
}

const featureToggle = new FeatureToggle(features)

export default featureToggle
