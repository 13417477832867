import LoadingSpinner from '@thebeansgroup/ui_loaders/loading_spinner'

import { PAGE_LOADING_TEST_ID } from './constants'
import * as styles from './styles'

const PageLoading = () => (
  <div
    css={styles.wrapper}
    data-testid={PAGE_LOADING_TEST_ID}
  >
    <LoadingSpinner
      height='50px'
      width='50px'
      variant='coloured'
      label='Loading'
    />
  </div>
)

export default PageLoading
