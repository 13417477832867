import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import { Typography, Menu, MenuItem, Button } from '@mui/material'
import cookies from 'js-cookie'
import React, { useState } from 'react'

import { TOKEN_COOKIE } from '@components/pages/login/constants'
import { useHistory } from '@hooks/react-router-dom'
import { useUserEmail } from '@hooks/use_viewer_data'

import { LOGOUT_TEXT } from './constants'

const NavigationBarUserMenu = (): JSX.Element => {
  const history = useHistory()
  const userEmail = useUserEmail()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseUserMenu = (): void => {
    setAnchorEl(null)
  }

  const renderMenuButton = (): JSX.Element => {
    return (
      <Button
        onClick={handleOpenUserMenu}
        variant='text'
        sx={{ color: 'white' }}
      >
        {userEmail}
        <KeyboardArrowDownIcon />
      </Button>
    )
  }

  const handleLogout = (): void => {
    cookies.remove(TOKEN_COOKIE, {
      domain: process.env.NEXT_PUBLIC_SSO_TOKEN_DOMAIN
    })
    history.reload()
  }

  const renderMenu = (): JSX.Element => {
    return (
      <Menu
        sx={{ mt: '49px', ml: '-20px' }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          component='a'
          onClick={handleLogout}
        >
          <Typography textAlign='left'>{LOGOUT_TEXT}</Typography>
        </MenuItem>
      </Menu>
    )
  }

  return (
    <React.Fragment>
      {renderMenuButton()}
      {renderMenu()}
    </React.Fragment>
  )
}

export default NavigationBarUserMenu
