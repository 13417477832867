import { Theme } from '@mui/material/styles'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

type ThemeInterface = Exclude<Theme['components'], undefined>

const MuiTableCell: ThemeInterface['MuiTableCell'] = {
  styleOverrides: {
    root: {
      fontSize: '13px',
      color: Colours('liquorice', 500)
    }
  }
}

export default MuiTableCell
