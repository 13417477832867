import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const getBackgroundColor = () => {
  const environment = process.env.NEXT_PUBLIC_APP_ENV

  if (environment === 'development') return Colours('violet', 300)

  if (environment === 'staging') return Colours('sourCherry', 300)

  return Colours('uiBlue', 300)
}
